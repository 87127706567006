import React, { useContext } from 'react';

import LocaleContext from 'scripts/contexts/locale';

import flagGb from 'assets/images/flag-gb.svg';
import flagSe from 'assets/images/flag-se.svg';

import styles from './LanguageSwitch.module.scss';

const LanguageSwitch = () => {
  const { locale, setLocale } = useContext(LocaleContext);

  const onClickEn = event => {
    event.preventDefault();

    setLocale('en');
  };

  const onClickSe = event => {
    event.preventDefault();

    setLocale('sv');
  };

  return (
    <nav className={styles.wrapper}>
      {locale === 'sv' && (
        <button
          type="button"
          className={styles.button}
          style={{
            backgroundImage: `url(${flagGb})`,
          }}
          onClick={onClickEn}
        >
          EN
        </button>
      )}

      {locale === 'en' && (
        <button
          type="button"
          className={styles.button}
          style={{
            backgroundImage: `url(${flagSe})`,
          }}
          onClick={onClickSe}
        >
          EN
        </button>
      )}
    </nav>
  );
};

export default React.memo(LanguageSwitch);
