import React, { useContext } from 'react';
import { func } from 'prop-types';
import { translate } from 'react-polyglot';

import LocaleContext from 'scripts/contexts/locale';

import policyPdfSv from 'assets/files/MW_AB_integritetspolicy_2020-03-31.pdf';
import policyPdfEn from 'assets/files/MW_AB_integritetspolicy_2020-04-23_EN.pdf';

import styles from './Footer.module.scss';

const Footer = ({ t }) => {
  const { locale } = useContext(LocaleContext);

  return (
    <footer className={styles.wrapper}>
      <ul className={styles.menu}>
        {/*
        <li>
          <a href="#" className={styles.link} target="_blank" rel="noopener">
            Allmänna villkor
          </a>
        </li>
        */}
        <li>
          <a
            href={locale === 'en' ? policyPdfEn : policyPdfSv}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.privacy_policy')}
          </a>
        </li>
      </ul>
    </footer>
  );
};

Footer.propTypes = {
  t: func,
};

Footer.defaultProps = {
  t: phrase => phrase,
};

export default translate()(Footer);
