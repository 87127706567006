import React, { useContext } from 'react';

import LocaleContext from 'scripts/contexts/locale';

import styles from './PracticeAreas.module.scss';

const PracticeAreas = () => {
  const { locale } = useContext(LocaleContext);

  const content = {
    en: (
      <div className={styles.content}>
        <h2 className={styles.title}>Practice areas</h2>
        <p>M&A</p>
        <p>
          Corporate commercial: consortiums, joint ventures, purchase and supply
          agreements, licensing agreements, agency and distribution agreements
        </p>
        <p>
          Corporate finance: corporate law, governance, securities law, IPO, LBO
          and other financing agreements
        </p>
        <p>Foundation law</p>
        <p>Dispute resolution</p>
        <p>Property law</p>
      </div>
    ),
    sv: (
      <div className={styles.content}>
        <h2 className={styles.title}>Verksamhetsområden</h2>

        <p>M&A</p>
        <p>
          Corporate commercial: konsortialavtal, joint venture-samarbete,
          inköps- och leverensavtal, licensavtal, agent- och återförsäljaravtal
        </p>
        <p>
          Corporate finance: bolagsrätt, governance, börsrätt, IPO, LBO- och
          andra finansieringsavtal
        </p>
        <p>Stiftelserätt</p>
        <p>Tvistelösning</p>
        <p>Fastighetsrätt</p>
      </div>
    ),
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>{content[locale]}</div>
    </div>
  );
};

export default PracticeAreas;
