export const phrases = {
  menu: {
    home: 'Contact',
    employees: 'Team',
    practice_areas: 'Practice areas',
  },
  footer: {
    privacy_policy: 'Privacy policy',
  },
  contact: {
    title: 'Contact',
    country: 'Sweden',
    address: 'Address',
    phone: 'Telephone',
    email: 'E-mail',
    registration_number: 'Company registration number',
  },
  employees: {
    education: 'Education',
    experience: 'Experience',
    engagements: 'Engagements',
  },
};

export default phrases;
