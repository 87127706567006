import React, { useEffect, useState } from 'react';
import { I18n } from 'react-polyglot';
import { Switch, Route } from 'react-router-dom';

import translations from 'scripts/translations/translations';

import LocaleContext from 'scripts/contexts/locale';

import Header from 'scripts/components/Header/Header';
import Menu from 'scripts/components/Menu/Menu';
import Footer from 'scripts/components/Footer/Footer';

import Contact from 'scripts/components/Contact/Contact';
import Employees from 'scripts/components/Employees/Employees';
import PracticeAreas from 'scripts/components/PracticeAreas/PracticeAreas';

import styles from './App.module.scss';

const App = () => {
  const [locale, setLocale] = useState('sv');
  const [messages, setMessages] = React.useState(translations[locale]);

  useEffect(() => setMessages(translations[locale]), [locale]);

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <I18n locale={locale} messages={messages}>
        <main className={styles.wrapper}>
          <Header />
          <Menu />

          <div className={styles.content}>
            <Switch>
              <Route exact path="/" component={Contact} />
              <Route exact path="/medarbetare" component={Employees} />
              <Route
                exact
                path="/verksamhetsomraden"
                component={PracticeAreas}
              />
            </Switch>
          </div>

          <Footer />
        </main>
      </I18n>
    </LocaleContext.Provider>
  );
};

export default App;
