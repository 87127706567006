export const createUrl = (context, slug1) => {
  switch (context) {
    case 'home':
      return '/';

    case 'employees':
      return '/medarbetare';

    case 'practice-areas':
      return '/verksamhetsomraden';

    default:
      return `/${slug1}`;
  }
};

// Check if URL is external.
export const isExternal = path => /^https?:\/\//.test(path);
