import React, { useContext } from 'react';
import { func } from 'prop-types';
import { translate } from 'react-polyglot';

import LocaleContext from 'scripts/contexts/locale';

import magnusImage from 'assets/images/magnus-wittbom.jpg';
import sigridImage from 'assets/images/sigrid-wittbom.jpg';

import styles from './Employees.module.scss';

const Employees = ({ t }) => {
  const { locale } = useContext(LocaleContext);
  const isEnglish = locale === 'en';

  const items = [
    {
      id: 'magnus',
      image: magnusImage,
      name: 'Magnus Wittbom',
      title: isEnglish
        ? 'Member of the Swedish Bar Association and partner'
        : 'Advokat och innehavare',
      phone: '+46 (0)708 59 59 64',
      email: 'magnus@wittbomlaw.com',
      linkedin: 'http://se.linkedin.com/pub/magnus-wittbom/13/420/768',
      blocks: [
        {
          title: t('employees.education'),
          content: isEnglish ? (
            <p>
              LL.M., Lund University, 1985
              <br />
              Scandinavian Institute of Maritime Law, University of Oslo, 1985
              <br />
              Stanford Executive Program, 2002
            </p>
          ) : (
            <p>
              Jur. kand., Lunds universitet, 1985
              <br />
              Sjörättsexamen, Skandinaviska institutet för sjörätt, Oslo
              universitet, 1985
              <br />
              Stanford Executive Program, 2002
            </p>
          ),
        },
        {
          title: t('employees.experience'),
          content: isEnglish ? (
            <p>
              Law clerk, Falköping District Court, 1985-1987
              <br />
              Reporting clerk Court of Appeal for Western Sweden, 1988-1989
              <br />
              Legal counsel/general counsel, ESAB, 1990-1995
              <br />
              General counsel, Gränges/Sapa, 1995-2006
              <br />
              General counsel, Gambro/Gambro Holding, 2006-2010
              <br />
              Various law firms, 2011-2014
              <br />
              Magnus Wittbom Advokatbyrå AB, 2014-
            </p>
          ) : (
            <p>
              Tingsnotarie, Falköpings tingsrätt, 1985-1987
              <br />
              Hovrättsfiskal, Hovrätten för Västra Sverige, 1988-1989
              <br />
              Bolagsjurist/chefjurist, ESAB, 1990-1995
              <br />
              Chefjurist, Gränges/Sapa, 1995-2006
              <br />
              Chefjurist, Gambro/Gambro Holding, 2006-2010
              <br />
              Medarbetare på advokatbyråer, 2011-2014
              <br />
              Magnus Wittbom Advokatbyrå AB, 2014-
            </p>
          ),
        },
        {
          title: t('employees.engagements'),
          content: isEnglish ? (
            <p>
              Member of the Swedish bar
              <br />
              Chairman of the board in a Swedish medical-surgical research
              foundation
              <br />
              Member of VQ Legal’s expert panel in the field of M&A and
              commercial contract law
            </p>
          ) : (
            <p>
              Medlem i Sveriges Advokatsamfund
              <br />
              Ordförande i medicinsk-kirurgisk forskningsstiftelse
              <br />
              Medlem i VQ Legals expertgrupp inom M&A och kommersiell avtalsrätt
            </p>
          ),
        },
      ],
    },
    {
      id: 'sigrid',
      image: sigridImage,
      name: 'Sigrid Wittbom',
      title: isEnglish ? 'Member of the Swedish Bar Association' : 'Advokat',
      phone: '+46 (0)766 23 03 44',
      email: 'sigrid@wittbomlaw.com',
      linkedin: 'https://linkedin.com/in/sigrid-wittbom-5302a548',
      blocks: [
        {
          title: t('employees.education'),
          content: isEnglish ? (
            <p>LL.M., Lund University, 2013</p>
          ) : (
            <p>Jur. kand., Lunds universitet, 2013</p>
          ),
        },
        {
          title: t('employees.experience'),
          content: isEnglish ? (
            <p>
              Legal counsel, AB Traction (publ), 2012-2013
              <br />
              Law clerk, Södertälje District Court, 2013-2015
              <br />
              Associate, MAQS Advokatbyrå AB, 2015-2018
              <br />
              Magnus Wittbom Advokatbyrå AB, 2018-
            </p>
          ) : (
            <p>
              Bolagsjurist, AB Traction, 2012-2013
              <br />
              Tingsnotarie, Södertälje tingsrätt, 2013-2015
              <br />
              Biträdande jurist, MAQS Advokatbyrå AB, 2015-2018
              <br />
              Magnus Wittbom Advokatbyrå AB, 2018-
            </p>
          ),
        },
        {
          title: t('employees.engagements'),
          content: isEnglish ? (
            <p>
              Member of the Swedish bar
              <br />
              Member of VQ Legal’s expert panel in the field of M&A and
              commercial contract law
            </p>
          ) : (
            <p>
              Medlem i Sveriges Advokatsamfund
              <br />
              Medlem i VQ Legals expertgrupp inom M&A och kommersiell avtalsrätt
            </p>
          ),
        },
      ],
    },
  ];

  return (
    <div className={styles.wrapper}>
      {items.map(
        ({ id, image, name, title, phone, email, linkedin, blocks }) => (
          <article key={id} className={styles.person}>
            <div className={styles.row}>
              <div className={styles.image}>
                <img src={image} alt={name} loading="lazy" />
              </div>
              <div className={styles.content}>
                <header className={styles.intro}>
                  <h2 className={styles.title}>{name}</h2>

                  <p>{title}</p>

                  <p>
                    <span className={styles.label}>{t('contact.phone')}: </span>
                    {phone}
                    <br />
                    <span className={styles.label}>{t('contact.email')}: </span>
                    <a href={`mailto:${email}`}>{email}</a>
                    <br />
                    <a
                      href={linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </a>
                  </p>
                </header>

                {blocks.map(({ title: blockTitle, content }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`block-${id}-${index}`} className={styles.block}>
                    <h3 className={styles.subTitle}>{blockTitle}</h3>

                    {content}
                  </div>
                ))}
              </div>
            </div>
          </article>
        )
      )}
    </div>
  );
};

Employees.propTypes = {
  t: func,
};

Employees.defaultProps = {
  t: phrase => phrase,
};

export default translate()(Employees);
