import React, { useContext } from 'react';
import { func } from 'prop-types';
import { translate } from 'react-polyglot';

import LocaleContext from 'scripts/contexts/locale';

import styles from './Contact.module.scss';

const Contact = ({ t }) => {
  const { locale } = useContext(LocaleContext);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('contact.title')}</h2>

      <p>Magnus Wittbom Advokatbyrå AB</p>

      <p>
        {t('contact.address')}: Eriksbergsgatan 8A
        <br />
        {locale === 'en' ? 'SE -' : ''} 114 30 Stockholm, {t('contact.country')}
      </p>

      <p>
        {t('contact.phone')}: +46 (0)708 59 59 64
        <br />
        {t('contact.email')}:{' '}
        <a href="mailto:magnus@wittbomlaw.com">magnus@wittbomlaw.com</a>
      </p>

      <p>{t('contact.registration_number')}: 556819-4327</p>
    </div>
  );
};

Contact.propTypes = {
  t: func,
};

Contact.defaultProps = {
  t: phrase => phrase,
};

export default translate()(Contact);
