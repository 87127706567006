import React from 'react';

import logotypeImage from 'assets/images/logotype-mwa@2x.png';

import styles from './Header.module.scss';

const Header = () => {
  return (
    <header className={styles.wrapper}>
      <img
        src={logotypeImage}
        alt="Magnus Wittbom Advokatbyrå AB"
        className={styles.image}
      />

      <h1 className={styles.title}>Magnus Wittbom Advokatbyrå AB</h1>
    </header>
  );
};

export default Header;
