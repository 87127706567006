export const phrases = {
  menu: {
    home: 'Kontakt',
    employees: 'Medarbetare',
    practice_areas: 'Verksamhetsområden',
  },
  footer: {
    privacy_policy: 'Integritetspolicy',
  },
  contact: {
    title: 'Kontakt',
    country: 'Sverige',
    address: 'Adress',
    phone: 'Telefon',
    email: 'E-post',
    registration_number: 'Org.nr.',
  },
  employees: {
    education: 'Utbildning',
    experience: 'Erfarenhet',
    engagements: 'Åtaganden',
  },
};

export default phrases;
