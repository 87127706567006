import React from 'react';
import { func } from 'prop-types';
import { translate } from 'react-polyglot';
import { NavLink } from 'react-router-dom';

import { createUrl } from 'scripts/utils/urlHelper';

import LanguageSwitch from 'scripts/components/LanguageSwitch/LanguageSwitch';

import styles from './Menu.module.scss';

const Menu = ({ t }) => {
  return (
    <div className={styles.wrapper}>
      <nav className={styles.menu}>
        <NavLink
          exact
          to={createUrl('home')}
          className={styles.link}
          activeClassName={styles.active}
        >
          {t('menu.home')}
        </NavLink>
        <NavLink
          exact
          to={createUrl('employees')}
          className={styles.link}
          activeClassName={styles.active}
        >
          {t('menu.employees')}
        </NavLink>
        <NavLink
          exact
          to={createUrl('practice-areas')}
          className={styles.link}
          activeClassName={styles.active}
        >
          {t('menu.practice_areas')}
        </NavLink>
      </nav>

      <LanguageSwitch />
    </div>
  );
};

Menu.propTypes = {
  t: func,
};

Menu.defaultProps = {
  t: phrase => phrase,
};

export default translate()(Menu);
